<template>
  <v-dialog v-model="show" width="600">
    <v-card>
      <v-card-title>Выберите версию для удаления</v-card-title>
      <v-divider />
      <v-card-subtitle>
        <VersionSelection
          :filter="filter"
          :selected="selected"
          @selectVersion="onVersionSelected"
          @versionsCount="checkAvailableDialog"
        ></VersionSelection>
      </v-card-subtitle>
      <v-card-text class="pt-4 body-1">
        {{text}} 
      </v-card-text>
      <v-card-text class="pt-4 body-1"> 
        {{ confirmationText }}
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn 
          @click="show = false" 
          color="red" 
          outlined
        > 
          Отмена 
        </v-btn>
        <v-spacer />
        <v-btn 
          @click="deleteRouteVersion" 
          :loading="loading"
          :disabled="disabledDelete"
          color="primary" 
          dark
        > 
          Удалить 
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  const NEW_STATUS_GUID = 'c2cd5446-7849-4ab4-89f8-bcef841fd2a0';
  const PROJECT_STATUS_GUID = 'ed4762ee-ca91-4b52-89e8-1176fb0a296e';
  
  import VersionSelection from '@/components/actions/RoutesSettings/VersionSelection';

  export default {
    name: "DeleteRouteVersion",
    components: {
      VersionSelection,
    },
    props: {
      collection: {
        type: Object,
        required: true,
      }
    },
    data: () => ({
      show: true,
      loading: false,
      selected: null,
      text: '',
      filter: '',
      disabledDelete: false,
      confirmationText: '',
    }),
    created() {
      this.selected = this.collection.master.selected;
      if (!this.selected) {
        this.show = false;
        jet.msg({
          text: 'Необходимо выбрать запись!',
          color: 'warning'
        });
        return;
      }
      this.filter = `and(eq(field(".verID"),param("${this.selected.vcroutesVerid}","id")),eq(field(".verStatus"),param("${PROJECT_STATUS_GUID}","id"))`;
    },
    methods: {
      async worker() {
        const certificatesRaw = await this.getCertificates(this.selected.vcroutesId);
        const cic = certificatesRaw.result.columnIndexes;
        const certificates = certificatesRaw.result.data;
        if (certificates.length) {
          const badCertificates = certificates.find(it => it[cic["vccertificateregister.stateid"]] !== NEW_STATUS_GUID);
          if (badCertificates) {
            this.disabledDelete = true;
            this.text += 'Версия маршрута не может быть удалена, т.к. по ней выдано свидетельство.';
            return;
          } else {
            this.disabledDelete = false;
            this.text += 'По маршруту создано Свидетельство, которое будет удалено!';
          }
        } else {
          this.disabledDelete = false;
        }
        const cards = await this.getCards(this.selected.vcroutesId);
        if (cards.length) {
          this.disabledDelete = true;
          this.text += 'Версия маршрута не может быть удалена т. к. по ней выдана карта.';
          return;
        } else {
          this.disabledDelete = false;
        }
        const schedulesRaw = await this.getSchedules(this.selected.vcroutesId);
        const cis = schedulesRaw.result.columnIndexes;
        const schedules = schedulesRaw.result.data;
        if (schedules.length) {
          this.text += this.text === '' ? '' : ' ';
          this.text += 'По маршруту создано расписание, которое будет удалено!';
        }
        const trips = await this.getTrips(this.selected.vcroutesId);
        if (trips.length) {
          this.text += this.text === '' ? '' : ' ';
          this.text += 'По версии маршрута существуют постановки на рейс, которые будут удалены.';
        }
        if (this.disabledDelete === false) {
          this.confirmationText = `Вы действительно хотите удалить версию № ${this.selected.vcroutesVersion} ?`;
        }
      },
      onVersionSelected(item) {
        this.selected = item;
        this.text = '';
        if (this.selected.disabled === false) {
          this.worker();
        }
      },
      checkAvailableDialog(count) {
        if (count === 0) {
          this.show = false;
          jet.msg({
            text: 'Действие доступно только для статуса «Проект», у маршрута нет версий с таким статусом',
            color: 'warning'
          });
          return; 
        }
      },
      async deleteRouteVersion() {
        this.loading = true;
        let optionsQuery = {
          type: 'query',
          query: 'feb1b1ec-48b9-46d4-b59e-93d9ff3f1f09.removeRouteVersion',
          params: {
            in_routeID: this.selected.vcroutesId,
            in_userID: this.selected._sec_attrsUserid,
            in_tenantID: this.selected._sec_attrsTenantid,
            in_releaseScheduleId: '00000000-0000-0000-0000-000000000000',
            in_type: 1,
          }
        }
        try {
          const resp = await jet.http.post(optionsQuery);
          if (resp.error) {
            throw resp.error;
          }
          jet.msg({
            text: `Версия ${this.selected.vcroutesVersion} маршрута 
              № ${this.selected.vcroutesRoutecode} 
              ${this.selected.vcroutesRoutename} успешно удалена!`
          });
        } catch (e) {
          jet.msg({
            text: 'Не удалось удалить версию маршрута.',
            color: 'warning'
          });
          console.error('DeleteRouteVersion: deleteRouteVersion()', e);
        }
        this.show = false;
        this.loading = false;
        this.collection.refresh(this.selected.vcroutesId);
      },
     async getSchedules(routeId){
       const schedules = {
        type: 'core-read',
        query: 'sin2:/v:9622d81a-5411-4350-a324-a21c7604b261/'
                + '?filter=eq(field(".route"),param("'
                + routeId
                + '", "id"))'
      };
      return (await jet.http.post(schedules));
     },
     async getTrips(routeId){
       const schedules = {
        type: 'core-read',
        query: 'sin2:/v:41bbd718-7986-4796-83fb-f4f7805b1bf6/'
                + '?filter=eq(field(".route"),param("'
                + routeId
                + '", "id"))'
      };
      return (await jet.http.post(schedules)).result.data;
     },
     async getCertificates(routeId){
       const schedules = {
        type: 'core-read',
        query: 'sin2:/v:0e6241ee-2dd5-4860-89ba-0324c0f0e7fb/'
                + '?filter=eq(field(".servicedRouteID.route"),param("'
                + routeId
                + '", "id"))'
      };
      return (await jet.http.post(schedules));
     },
     async getCards(routeId){
       const cards = {
        type: 'core-read',
        query: 'sin2:/v:8f2e552f-1c34-4a70-b702-c729716ac96f/'
                + '?filter=eq(field(".servicedRouteID.route"),param("'
                + routeId
                + '", "id"))'
      };
      return (await jet.http.post(cards)).result.data;
     },
    },
  }
</script>